import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocalJsonForm } from "gatsby-tinacms-json"

import SEO from "../components/seo"
import { PhoneCarousel } from "../blocks/PhoneCarousel"
import { PlainSelector } from "../blocks/PlainSelector"
import { SolidBoi } from "../blocks/SolidBoi"
import { Basic } from "../blocks/Basic"
import { PageForm, Page } from "../templates/page"

const AboutPage = ({ data }) => {
  const [page]: any = useLocalJsonForm(data.dataJson, PageForm)

  return (
    <Page
      title={page?.title || ""}
      description={page?.description || ""}
      useFooterDl
    >
      <section className="uk-section uk-section-muted uk-overflow-hidden">
        <div className="uk-container uk-container-small">
          <div className="uk-child-width-1-1" data-uk-grid>
            {page?.blocks &&
              page?.blocks.map(({ _template, ...data }) => {
                switch (_template) {
                  case "SolidBoiBlock":
                    // @ts-ignore
                    return <SolidBoi {...data} />
                  case "PhoneCarouselBlock":
                    // @ts-ignore
                    return <PhoneCarousel {...data} />
                  case "BasicBlock":
                    // @ts-ignore
                    return <Basic {...data} />
                  case "PlainSelector":
                    return (
                      <PlainSelector
                        title={data?.title}
                        img={data?.img}
                        img_bg={data?.img_bg}
                        textData={data?.data}
                      />
                    )
                  default:
                    return true
                }
              })}
            <h2 style={{ textAlign: "center" }}>Investors</h2>
            <div className="uk-text-center">
              <div className="uk-flex-middle uk-flex-center" data-uk-grid>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <img
                    className="uk-width-small"
                    src="/investor-dundee.png"
                    alt="Dundee VC"
                  />
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <img
                    className="uk-width-medium"
                    src="/investor-calano.png"
                    alt="Calano Ventures"
                  />
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <img
                    className="uk-width-medium"
                    src="/investor-techstars.png"
                    alt="Techstars"
                  />
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <img
                    className="uk-width-medium"
                    src="/investor-fvf.png"
                    alt="Fintech Ventures Fund"
                  />
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <img
                    className="uk-width-medium"
                    src="/investor-spc.png"
                    alt="Service Provider Capital"
                    style={{ maxHeight: 120, width: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

export const query = graphql`
  query aboutQuery {
    dataJson(fileRelativePath: { eq: "/src/data/about.json" }) {
      title
      description
      blocks {
        _template
        title
        style
        data {
          title
          subtitle
          text
          img
          link
        }
      }

      # TinaCMS fields
      rawJson
      fileRelativePath
    }
  }
`

export default AboutPage
